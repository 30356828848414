import React from 'react';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  const title =
    'ページが見つかりませんでした｜奈良のホームページ制作なら｜インヴォルブ';
  const description =
    '当サイトに存在しないページです。URLをご確認いただくか再度トップページよりご訪問ください。';
  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    {
      name: 'ページが見つかりませんでした',
      url: 'https://www.involve-in.jp/404',
    },
  ];

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.involve-in.jp/404/" />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />

        {/* Open Graph (OGP: Facebook, LinkedIn) */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.involve-in.jp/404" />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
      </Helmet>
      <section
        id="errormsg"
        className="flex flex-col items-center justify-center px-5 py-36 text-center"
      >
        <h1 className="text-3xl font-bold">404 - ページが見つかりません</h1>
        <p className="mt-5 text-lg font-bold leading-relaxed">
          お探しのページは存在しないか、移動されました。
        </p>
        <div className="mt-6">
          <a
            href="/"
            className="block w-40 px-4 py-2 text-white bg-gray-800 border border-gray-800 rounded-md text-center hover:bg-white hover:text-gray-800 transition"
          >
            トップページへ戻る
          </a>
        </div>
      </section>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export default NotFoundPage;
